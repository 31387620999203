import jsQR from 'jsqr';

export const MEDIA_QUERY_SMALL = '(max-width: 576px)';

export const isFileAQR = async (file, canvasElement) => {
    return new Promise((resolve, reject) => {
        const ctx = canvasElement.getContext('2d');

        const img = new Image();
        img.onload = () => {
            canvasElement.width = img.width;
            canvasElement.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, canvasElement.width, canvasElement.height);
            const qrCode = jsQR(imageData.data, imageData.width, imageData.height);

            if (qrCode) {
                console.log('QR Code Data:', qrCode.data);
                resolve(true); // QR code found
            } else {
                console.log('No QR code found');
                resolve(false); // No QR code found
            }
        };

        img.onerror = () => {
            reject(new Error('Error in loading image'));
        };

        img.src = URL.createObjectURL(file);
    });
};
