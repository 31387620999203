import { useEffect, useState } from 'react';

// import styles from "./index.module.css";
import { useNavigate } from 'react-router-dom';

import GoogleLogin from '../../components/GoogleLogin';
import ReCAPTCHAWrapper from '../../components/ReCAPTCHAWrapper';
import { IsLoggedIn } from '../../firebase/auth';
import layoutStyles from '../../layout.module.css';

const Login = () => {
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const navigate = useNavigate();

    const [user] = IsLoggedIn();

    useEffect(() => {
        if (user) {
            navigate('/distribute');
        }
    }, [user, navigate]);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    return (
        <div className={layoutStyles['App']}>
            <header className={layoutStyles['App-header']}>
                <span className={layoutStyles['title']}>🇵🇸</span>
                <h1 className={layoutStyles['title']}>Administrator Access</h1>
                {!recaptchaValue && <p>Please complete the reCAPTCHA.</p>}
                <ReCAPTCHAWrapper onCaptchaActivity={handleRecaptchaChange} />
                {recaptchaValue ? <GoogleLogin redirect={'/distribute'} /> : null}
            </header>
        </div>
    );
};

export default Login;
