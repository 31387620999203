import styles from './index.module.css';
import LoadingSpinner from '../LoadingSpinner';

// State enum
export const STATE = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    OK: 'OK',
};

const StatusIndicator = ({ status, simCode }) => {
    // Shouldn't be undefined but handling just in case since
    if (status === undefined) {
        return <p>Unknown status</p>;
    }
    let message = status; // if error here will be the message
    if (status === STATE.OK && simCode && simCode.data) {
        message = `eSIM: ${simCode.data.esim_data}`;
        return (
            <div className={styles.container}>
                <div
                    className={styles.infoContainer}
                    style={{
                        wordBreak: 'break-all',
                        overflowWrap: 'break-word',
                    }}
                >
                    <b>{message}</b>
                </div>
            </div>
        );
    } else if (status === STATE.LOADING) {
        return (
            <div className={styles['status-indicator']}>
                <LoadingSpinner />
            </div>
        );
    }
    return <div className={styles['status-indicator']}>{message}</div>;
};

export default StatusIndicator;
