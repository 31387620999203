import { useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import styles from './index.module.css';

const ReCAPTCHAWrapper = forwardRef(({ onCaptchaActivity }, ref) => {
    const recaptchaRef = useRef(ref);

    const resetReCaptcha = useCallback(() => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            onCaptchaActivity(null);
        }
    }, [recaptchaRef, onCaptchaActivity]);

    useImperativeHandle(ref, () => ({
        // Define a function that can be called from outside
        resetReCaptcha,
    }));

    const handleCaptchaResponseChange = useCallback(
        (value) => {
            if (onCaptchaActivity) {
                if (value) {
                    // Captcha was solved
                    onCaptchaActivity(value);
                } else {
                    // Captcha was reset/unsolved
                    resetReCaptcha();
                }
            }
        },
        [resetReCaptcha, onCaptchaActivity]
    );

    const handleCaptchaExpired = () => {
        resetReCaptcha();
    };

    return (
        <>
            <ReCAPTCHA
                className={styles['recaptcha']}
                ref={recaptchaRef}
                sitekey="6LfXsu4oAAAAACVpZdpm_oBUH7SeKbHPXeCohqLf"
                onChange={handleCaptchaResponseChange}
                onExpired={handleCaptchaExpired}
            />
        </>
    );
});

ReCAPTCHAWrapper.displayName = 'ReCAPTCHAWrapper';

export default ReCAPTCHAWrapper;
