import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import style from './index.module.css';
import { IsLoggedIn, SignInWithGoogle } from '../../firebase/auth';

const GoogleLogin = (redirect) => {
    const navigate = useNavigate();
    const [user, isAuthenticating, error] = IsLoggedIn();

    useEffect(() => {
        if (user) {
            navigate(redirect);
        }
    }, [user, isAuthenticating, navigate, redirect]);

    return isAuthenticating ? (
        <div>Authenticating...</div>
    ) : (
        <div>
            {error ? <div>Error: {error}</div> : null}
            <button
                onClick={() => {
                    SignInWithGoogle();
                }}
                className={style['button']}
            >
                <span className={`${style['c8cb4305a']} ${style['cb95f79d4']}`}></span>
                <span className={style['c2592ea5f']}>Continue with Google</span>
            </button>
        </div>
    );
};

export default GoogleLogin;
