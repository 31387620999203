import styles from './index.module.css';

export const COMFY_BUTTON_TYPE = {
    ACTION: 'action',
    INFO: 'info',
    PLAIN: 'plain',
};

const ComfyButton = ({ onClick, disabled = false, children, type = COMFY_BUTTON_TYPE.ACTION }) => {
    const onClickHandler = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };
    return (
        <button
            onClick={onClickHandler}
            className={`${styles['button']} ${styles[`button-${type}`]} ${
                disabled ? styles['button-disabled'] : ''
            }`}
        >
            {children}
        </button>
    );
};

export default ComfyButton;
