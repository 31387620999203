import instruction1 from '../../images/instruction1.png';
import instruction2 from '../../images/instruction2.png';
import instruction3 from '../../images/instruction3.png';
export default function HowItWorks() {
    return (
        <section>
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                <p className="text-center text-sm font-bold uppercase">3 easy steps</p>
                <h2 className="mb-12 mt-4 text-center text-3xl  font-bold md:text-5xl ">
                    How It Works
                </h2>

                <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3">
                    <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#dfdfdf] bg-white p-8 md:p-10">
                        <img alt="Instruction 1" className="w-[5rem]" src={instruction1} />
                        <p className="text-2xl font-semibold">Purchase Your eSim</p>
                        <p className="text-sm text-gray-500">
                            Not sure which eSims to buy and where?{' '}
                            <button
                                onClick={() => {
                                    // Smooth scroll to the submit section
                                    document
                                        .getElementById('providers')
                                        .scrollIntoView({ behavior: 'smooth', offsetTop: 200 });
                                }}
                                className="font-medium text-green-600 hover:underline"
                            >
                                Check out our recommendations
                            </button>
                            .
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#dfdfdf] bg-white p-8 md:p-10">
                        <img alt="Instruction 2" className="w-[5rem]" src={instruction2} />

                        <p className="text-2xl font-semibold">Upload It to Our Website</p>
                        <p className="text-sm text-gray-500">
                            After you purchase your eSim, you will receive a QR code. You can take a
                            screenshot of the QR code and upload it to our website.
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 rounded-lg border border-solid border-[#dfdfdf] bg-white p-8 md:p-10">
                        <img alt="Instruction 3" className="w-[5rem]" src={instruction3} />
                        <p className="text-2xl font-semibold">We Distribute Them in Palestine</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
