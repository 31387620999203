import styles from './index.module.css';
import logo_small from '../../images/logo_small.png';

const Header = () => {
    return (
        <header className={styles['header']}>
            <img className={styles['logo']} alt="SIMs for Gaza logo" src={logo_small} />
            <div className="ml-4 tracking-tighter">Sims For Gaza</div>
        </header>
    );
};

export default Header;
