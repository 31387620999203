import { httpsCallable } from 'firebase/functions';

import { functions } from '../configuration/firebase/Firebase';

export const getAllUnusedSims = async () => {
    const cfGetAllUnusedSims = httpsCallable(functions, 'getAllUnusedSims');
    return cfGetAllUnusedSims({})
        .then((result) => {
            // Read result of the Cloud Function.
            console.log('Retrieved SIM');
            return result.data;
        })
        .catch((error) => {
            // Getting the Error details.
            // const code = error.code;
            const message = error.message;
            // const details = error.details;
            // Handle the error here.
            console.error('Error whilst retrieving SIMs:', message);
            throw error;
        });
};

export const nextSim = async (prevSimId, prevSimAction) => {
    const cfNextSim = httpsCallable(functions, 'nextSim');
    return cfNextSim({ prevSimId, prevSimAction })
        .then((result) => {
            const nextSim = result.data.nextSim;
            if (!nextSim) {
                throw new Error(`${result.data.message}`);
            }
            return nextSim;
        })
        .catch((error) => {
            const message = error.message;
            console.error('Error whilst retrieving next SIM', message);
            throw error;
        });
};

export const monitor = async () => {
    const cdMonitor = httpsCallable(functions, 'monitor');
    return cdMonitor({})
        .then((result) => {
            return result.data;
        })
        .catch((error) => {
            const message = error.message;
            console.error('Error whilst retrieving monitoring', message);
            throw error;
        });
};

export const setSimUsed = async (id, used) => {
    const cfSetSimUsed = httpsCallable(functions, 'setSimUsed');
    return cfSetSimUsed({ id, used })
        .then((result) => {
            console.log('Set SIM used status');
            return result.data;
        })
        .catch((error) => {
            const message = error.message;
            console.error('Error whilst updating SIM used status:', message);
            throw error;
        });
};
