export default function Footer() {
    return (
        <footer className="border-t bg-white px-5 py-10">
            <div className="mx-auto flex max-w-6xl justify-between ">
                <div className="space-y-4">
                    <p className="text-lg font-semibold text-gray-800">SimsForGaza</p>
                    <p>© 2023. All rights reserved</p>
                </div>
                <div className="space-y-4">
                    <a
                        href="https://twitter.com/SimsForGaza"
                        target="_blank"
                        rel="noreferrer"
                        className="text-gray-600 transition duration-150 ease-in-out hover:text-gray-800"
                    >
                        Twitter
                    </a>
                </div>
            </div>
        </footer>
    );
}
