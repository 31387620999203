export default function HeroSection() {
    return (
        <section className="relative isolate overflow-hidden bg-gradient-to-b from-green-100/20 to-green-100/40 md:pt-14">
            <div className="mx-auto max-w-7xl px-6 py-10 lg:px-8">
                <div className="flex flex-col gap-8 md:flex-row">
                    <div className="mt-16 flex flex-col space-y-8 max-md:items-center md:w-1/2">
                        <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 max-md:text-center sm:text-6xl lg:col-span-2 xl:col-auto">
                            Help Gaza Stay Online
                        </h1>
                        <p className="text-xl leading-8 text-gray-800 max-md:text-center">
                            Donate eSIMs through our platform to assist in medical requests and keep
                            families connected amid network disruptions.
                        </p>
                        <button
                            type="button"
                            onClick={() => {
                                // Smooth scroll to the submit section
                                document
                                    .getElementById('submit')
                                    .scrollIntoView({ behavior: 'smooth', offsetTop: 200 });
                            }}
                            className="flex w-fit items-center space-x-2 rounded-md bg-green-600 px-6 py-4 text-xl font-semibold text-white shadow-sm transition duration-300 ease-in-out hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <span>Donate eSIMs</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-5 w-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </div>
                    <img
                        src="https://framerusercontent.com/images/FIIncwrHDX9lCzGi9cRuFKNGgqA.jpg?scale-down-to=1024"
                        alt=""
                        className="mx-auto aspect-[6/5] w-full max-w-lg rounded-2xl object-cover max-md:mt-4 md:w-1/2 lg:max-w-none"
                    />
                </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </section>
    );
}
