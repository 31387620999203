import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './index.module.css';
import ESIMCode from '../../../src/components/ESIMCode';
import LogoutButton from '../../components/LogoutButton';
import StatusIndicator, { STATE } from '../../components/StatusIndicator';
import { IsLoggedIn, logout } from '../../firebase/auth';
import { nextSim } from '../../firebase/functions';
import layoutStyles from '../../layout.module.css';

function DistributeSim() {
    const [user, isAuthenticating, error] = IsLoggedIn();
    const [simCode, setSimCode] = useState(null);
    const [currentStatus, setCurrentStatus] = useState(STATE.OK);

    const navigate = useNavigate();

    const getNextSim = useCallback(
        async (prevSimId, prevSimAction) => {
            setCurrentStatus(STATE.LOADING);

            nextSim(prevSimId, prevSimAction)
                .then((unusedSimCode) => {
                    setCurrentStatus(STATE.OK);
                    setSimCode(unusedSimCode);
                })
                .catch((error) => {
                    console.error('getNextSim error', error);

                    setCurrentStatus(
                        `${user ? user.email : 'Unknown email'}:
                        ${error.message} Contact administrator if error presists.`
                    );
                    setSimCode(null);
                });
        },
        [user]
    );

    useEffect(() => {
        if (isAuthenticating) {
            return;
        }

        if (user && user.email) {
            getNextSim(null, null);
        } else {
            logout();
            navigate('/login');
        }
    }, [user, navigate, isAuthenticating, error, getNextSim]);

    return (
        <div className={layoutStyles['App']}>
            <header className={layoutStyles['App-header']}>
                <div className={styles['auth-indicator']}>
                    {isAuthenticating ? (
                        'Authenticating...'
                    ) : user ? (
                        <LogoutButton />
                    ) : (
                        'Not authorized'
                    )}
                </div>
                <div>🇵🇸</div>
                <h1>eSIM Distribution</h1>
                <StatusIndicator status={currentStatus} simCode={simCode} />
            </header>
            <ESIMCode simCode={simCode} getNextSim={getNextSim} currentStatus={currentStatus} />
        </div>
    );
}

export default DistributeSim;
