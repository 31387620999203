import { useEffect, useRef, useState } from 'react';

import style from './index.module.css';

const Dropdown = ({ options, onSelect, defaultSelectedProvider }) => {
    const [selectedProvider, setSelectedProvider] = useState(defaultSelectedProvider.label);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);

    // Close the dropdown if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleOptionClick = (value, label) => {
        setSelectedProvider(label);
        onSelect(value);
        setIsDropdownOpen(false);
    };

    return (
        <>
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                data-dropdown-toggle="dropdown"
                className={`${style['select']} bg-whitesmoke-700 hover:bg-whitesmoke-800 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white`}
                type="button"
            >
                <span>{selectedProvider}</span>
                <svg
                    className="ms-3 h-2.5 w-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>

            <div
                ref={dropdownRef}
                className={`${style['dropDownContainer']} z-10${
                    isDropdownOpen ? '' : ' hidden'
                } w-44 divide-y divide-gray-100 rounded-lg bg-white shadow`}
            >
                <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                >
                    {options.map(({ label, value }) => (
                        <li key={value}>
                            <div
                                onClick={() => handleOptionClick(value, label)}
                                className="block px-4 py-2 hover:bg-gray-100"
                            >
                                {label}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default Dropdown;
