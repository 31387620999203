import { useEffect } from 'react';

import FileUploadSection from '../../components/FileUploadSection';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import HowItWorks from '../../components/HowItWorks';
import ProvidersSection from '../../components/ProvidersSection';

import { SignInAnon } from '../../firebase/auth';

const Home = () => {
    useEffect(() => {
        SignInAnon();
    }, []);

    return (
        <div>
            <Header />
            <HeroSection />
            <HowItWorks />
            <FileUploadSection />
            <ProvidersSection />
            <Footer />
        </div>
    );
};

export default Home;
