import QRCode from 'qrcode';
import { useEffect, useState } from 'react';

import styles from './index.module.css';

import MarkAsSharedModal from './MarkAsSharedModal';
import MarkAsUsedModal from './MarkAsUsedModal';
import NotWorkingModal from './NotWorkingModal';

import { STATE } from '../../components/StatusIndicator';

const ESIMCode = ({ simCode, getNextSim, currentStatus }) => {
    const [usedModalOpen, setUsedModalOpen] = useState(false);
    const [sharedModalOpen, setSharedModalOpen] = useState(false);
    const [notWorkingModalOpen, setNotWorkingModalOpen] = useState(false);
    const [simQRCodeURL, setSimQRCodeURL] = useState(null);

    useEffect(() => {
        if (simCode && currentStatus === STATE.OK) {
            // Get the storage reference from the given path.
            const esim_data = simCode.data.esim_data;
            QRCode.toDataURL(esim_data, {
                errorCorrectionLevel: 'quartile',
                scale: 8,
            })
                .then((url) => {
                    // If successful, set the state.
                    setSimQRCodeURL(url);
                })
                .catch((error) => {
                    // Something went wrong with getting the download URL. Get the next SIM.
                    setSimQRCodeURL(null);
                    getNextSim(null, null);
                });
        } else {
            setSimQRCodeURL(null);
        }
    }, [currentStatus, getNextSim, simCode]);

    const markAsUsed = () => {
        if (!simCode) {
            console.log('No SIM code to mark as used');
            return;
        }

        getNextSim(simCode.id, 'used');
        setUsedModalOpen(false);
    };

    const markAsShared = () => {
        if (!simCode) {
            console.log('No SIM code to mark as shared');
            return;
        }

        getNextSim(simCode.id, 'shared');
        setSharedModalOpen(false);
    };

    const markAsNotWorking = () => {
        if (!simCode) {
            console.log('No SIM code to mark as not working');
            return;
        }

        getNextSim(simCode.id, 'not_working');
        setNotWorkingModalOpen(false);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Scan QR Code</h2>

            {simQRCodeURL && (
                <div className={styles.qrCodeContainer}>
                    <img src={simQRCodeURL} alt="QR Code" className={styles.qrCode} />
                </div>
            )}

            <div className={styles.buttonContainer}>
                <button
                    className={styles.sharedButton}
                    onClick={() => setSharedModalOpen(true)}
                    disabled={!simQRCodeURL}
                >
                    Shared with Third-Party
                </button>
                <button
                    className={styles.usedButton}
                    onClick={() => setUsedModalOpen(true)}
                    disabled={!simQRCodeURL}
                >
                    Mark as Used
                </button>
                <button
                    className={styles.notWorkingButton}
                    onClick={() => setNotWorkingModalOpen(true)}
                    disabled={!simQRCodeURL}
                >
                    Mark as Not Working
                </button>
            </div>

            <div className={styles.infoContainer}>
                <p>
                    After scanning the QR code, please mark the SIM card as used if you were able to
                    successfully activate it.
                </p>
                <p>If the SIM card is not working, please mark it as not working.</p>
                <p>Afterwards, we will automatically retrieve the next SIM card for you.</p>
            </div>

            <div className={styles.infoContainer}>
                <p>
                    If you run into any issues, please contact us at{' '}
                    <a href="mailto:simsforgaza@gmail.com" className={styles.emailLink}>
                        simsforgaza@gmail.com
                    </a>
                </p>
            </div>

            {/* Used Modal */}
            <MarkAsUsedModal
                open={usedModalOpen}
                setOpen={setUsedModalOpen}
                onConfirm={markAsUsed}
            />

            <MarkAsSharedModal
                open={sharedModalOpen}
                setOpen={setSharedModalOpen}
                onConfirm={markAsShared}
            />

            {/* Not Working Modal */}
            <NotWorkingModal
                open={notWorkingModalOpen}
                setOpen={setNotWorkingModalOpen}
                onConfirm={markAsNotWorking}
            />
        </div>
    );
};
export default ESIMCode;
