// import styles from './App.module.css';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';

import Admin from './pages/admin';

import DistributeSim from './pages/distributeSim';

import Home from './pages/home';

import Login from './pages/login/Login';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<DistributeSim />} path="/distribute" />
                <Route element={<Admin />} path="/admin" />
                <Route element={<Login />} path="/login" />
                <Route element={<Navigate to="/" />} path="*" />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
