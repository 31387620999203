import FilePicker from '../../components/FilePicker';

export default function FileUploadSection() {
    return (
        <section
            id="submit"
            className="flex flex-col items-center justify-center space-y-12 px-5 pb-20"
        >
            <div className="max-w-3xl space-y-4">
                <h2 className="mt-4 text-center text-3xl  font-bold md:text-5xl ">
                    Submit QR Code Screenshot
                </h2>
                <p className="text-center text-xl">
                    Please submit a screenshot of your QR code below. eSims will be distributed
                    through verified charities, organisations and journalists.
                </p>
            </div>
            <div className="space-y-3">
                <FilePicker />
                <p className="max-w-5xl text-center text-lg  text-gray-600">
                    Please note that the activation of the donated eSim may not be guaranteed, as it
                    depends on factors such as recipient location, network coverage, and potential
                    technical issues during the eSim setup.
                </p>
            </div>
        </section>
    );
}
