import { useCallback, useEffect, useState } from 'react';

import GoogleLogin from '../../components/GoogleLogin';

import { IsLoggedIn } from '../../firebase/auth';

import { monitor } from '../../firebase/functions';

function Admin() {
    const [user, isAuthenticating, error] = IsLoggedIn();
    const [monitoringDetails, setMonitoringDetails] = useState(null);

    const getMonitoringDetails = useCallback(async () => {
        monitor()
            .then((results) => {
                setMonitoringDetails(results);
            })
            .catch((error) => {
                setMonitoringDetails(`${error.message} Contact administrator if error presists.`);
            });
    }, []);

    useEffect(() => {
        if (user && user.email) {
            getMonitoringDetails();
        } else {
            setMonitoringDetails(null);
        }
    }, [getMonitoringDetails, user, isAuthenticating, error]);

    return monitoringDetails ? (
        <div> {JSON.stringify(monitoringDetails, null, 2)} </div>
    ) : (
        <GoogleLogin redirect={'/admin'} />
    );
}

export default Admin;
