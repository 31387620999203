import styles from './index.module.css';
import { logout } from '../../firebase/auth';

const LogoutButton = () => {
    return (
        <div
            className={`${styles['button']} ${styles['logout-button']}`}
            onClick={() => {
                logout();
            }}
        >
            <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
            <div className={styles['text']}>Log out</div>
        </div>
    );
};

export default LogoutButton;
