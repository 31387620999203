import {
    getAuth,
    signInAnonymously,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import { firebaseApp } from '../configuration/firebase/Firebase';

const auth = getAuth(firebaseApp);

export const SignInAnon = () => {
    signInAnonymously(auth)
        .then(() => {
            // Signed in..
            console.log(`Signed in Anonymously.`);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`Failed to sign in anonymously, errorCode: ${errorCode}, ${errorMessage}`);
        });
};

export const SignInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({ prompt: 'select_account' });
        await signInWithPopup(auth, provider);
    } catch (error) {
        console.error(error);
    }
};

export const SignUpWithGoogle = async () => {
    try {
        await signInWithPopup(auth, new GoogleAuthProvider());
    } catch (error) {
        console.error(error);
    }
};

export const logout = () => signOut(auth);

export const IsLoggedIn = () => useAuthState(auth);
