import { useMediaQuery } from '@react-hook/media-query';

import { MEDIA_QUERY_SMALL } from '../../utils';

const providersSuggestionList = [
    {
        providerName: 'Nomad',
        recommendedPackage: 'Middle East - 5GB/10GB',
        purchaseLink: 'https://www.getnomad.app/en/middle-east-eSIM',
    },
    {
        providerName: 'Airalo - Global eSIMs',
        recommendedPackage: 'Data - Discover - 3GB',
        purchaseLink: 'https://www.airalo.com/middle-east-and-north-africa-esim',
    },
    {
        providerName: 'Airalo - Regional eSIMs',
        recommendedPackage: 'Middle East and North Africa - Menalink - 3GB',
        purchaseLink: 'https://www.airalo.com/middle-east-and-north-africa-esim',
    },
    {
        providerName: 'Mogo',
        recommendedPackage: 'Israel plan',
        purchaseLink: 'https://esim.ifreegroup.com/buy/2?group_id=139&product_id=349',
    },
    {
        providerName: 'Simly',
        recommendedPackage: 'Local destinations - Palestine - 10GB',
        purchaseLink: 'https://www.simly.io/',
    },
    {
        providerName: 'Holafly',
        recommendedPackage: 'Israel destination - Unlimited data - 20 days',
        purchaseLink: 'https://esim.holafly.com/esim-israel/',
    },
];

const buyRecommendedPlanActionText = 'Buy recommended plan ↗';

const ProvidersSection = () => {
    const isSmall = useMediaQuery(MEDIA_QUERY_SMALL);

    return (
        <section className="pb-10">
            <div className="mx-auto max-w-5xl space-y-6 px-4 sm:px-6 lg:px-8">
                <div className="space-y-4">
                    <h2 className="mt-4 text-center text-3xl  font-bold md:text-5xl ">
                        Recommended eSim Providers
                    </h2>

                    <p className="w-full text-center text-xl">
                        Unsure of which eSim to purchase? We have compiled a list of recommended
                        providers below.
                    </p>
                </div>
                <div className="relative w-full overflow-x-auto" id="providers">
                    <h4 className="my-6 text-center font-bold">Providers and packages</h4>
                    {isSmall ? (
                        <div>
                            {providersSuggestionList.map(
                                ({ providerName, recommendedPackage, purchaseLink }) => (
                                    <div
                                        key={providerName}
                                        className="mx-auto my-4 flex flex-col items-center justify-center border-b bg-white text-center"
                                    >
                                        <div className="font-bold">{'Provider'}</div>
                                        <div className="whitespace px-6 py-4 pt-0 font-medium text-gray-900">
                                            {providerName}
                                        </div>
                                        <div className="font-bold">{'Recommended package'}</div>
                                        <div className="px-6 py-4 pt-0">{recommendedPackage}</div>
                                        <div className="px-6 py-4 pt-0">
                                            <a
                                                href={purchaseLink}
                                                className="font-medium text-green-600 hover:underline"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {buyRecommendedPlanActionText}
                                            </a>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    ) : (
                        <table className="w-full text-left text-sm text-gray-500 rtl:text-right">
                            <thead className="bg-gray-50 text-xs uppercase text-gray-700">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Provider
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Recommended package
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Link to buy
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {providersSuggestionList.map(
                                    ({ providerName, recommendedPackage, purchaseLink }) => (
                                        <tr key={providerName} className="border-b bg-white">
                                            <th
                                                scope="row"
                                                className="whitespace-nowrap px-6 py-4 font-medium text-gray-900"
                                            >
                                                {providerName}
                                            </th>
                                            <td className="px-6 py-4">{recommendedPackage}</td>
                                            <td className="px-6 py-4">
                                                <a
                                                    href={purchaseLink}
                                                    className="font-medium text-green-600 hover:underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {buyRecommendedPlanActionText}
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ProvidersSection;
