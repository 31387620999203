import { CheckIcon } from '@heroicons/react/24/outline';

import PopupModal from '../PopupModal';

export default function MarkAsUsedModal({ open, setOpen, onConfirm }) {
    return (
        <PopupModal open={open} setOpen={setOpen}>
            <PopupModal.Content className="flex flex-col space-y-6 px-8 py-6 font-medium sm:max-w-sm">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <PopupModal.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                        >
                            Are you sure you want to mark this as used?
                        </PopupModal.Title>
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        Mark as Used
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                </div>
            </PopupModal.Content>
        </PopupModal>
    );
}
