import { CheckIcon } from '@heroicons/react/24/outline';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useRef, useState } from 'react';

import styles from './index.module.css';
import providersList from './providers.json';
import { functions } from '../../configuration/firebase/Firebase';
import { isFileAQR } from '../../utils';
import ComfyButton, { COMFY_BUTTON_TYPE } from '../ComfyButton';
import Dropdown from '../Dropdown';
import LoadingSpinner from '../LoadingSpinner';
import PopupModal from '../PopupModal';
import ReCAPTCHAWrapper from '../ReCAPTCHAWrapper';

// Limits must match uploadLocation CloudFunction
const MIN_UPLOAD_LIMIT_BYTES = 512;
const MAX_UPLOAD_LIMIT_MB = 4;
const MAX_UPLOAD_LIMIT_BYTES = MAX_UPLOAD_LIMIT_MB * 1024 * 1024;

const FilePicker = () => {
    const [selectedImageFile, setSelectedImageFile] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [imageUploadData, setImageUploadData] = useState(null);
    const [error, setError] = useState();
    const [isRunningBGJob, setIsRunningBGJob] = useState(false);
    const fileInputRef = useRef(null);
    const recaptchaRef = useRef(null);
    const hiddenQRCanvasRef = useRef(null);
    const [selectedProvider, setSelectedProvider] = useState(
        providersList[Math.floor(Math.random() * providersList.length)]
    );

    useEffect(() => {
        if (recaptchaRef.current) {
            recaptchaRef.current.resetReCaptcha();
        }
    }, [selectedImageFile, selectedProvider]);

    useEffect(() => {
        // This function will run when the component is mounted
        const handleBeforeUnload = (e) => {
            if (isRunningBGJob) {
                const message = 'Data is still loading. Do you want to leave?';
                e.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // This will run when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isRunningBGJob]);

    const handleInputFileClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!(await isFileAQR(file, hiddenQRCanvasRef.current))) {
                setError(
                    `Couldn't detect a QR code. Please upload a clear image of the eSIM QR code. If the problem persists, please, contact us.`
                );
                setSelectedImageFile(null);
            } else if (file.size <= MAX_UPLOAD_LIMIT_BYTES) {
                setError(undefined);
                setSelectedImageFile(file);
            } else {
                setError(`File is too large. The limit is ${MAX_UPLOAD_LIMIT_MB}MB.`);
                setSelectedImageFile(null);
            }
        }
    };

    const handleUploadClick = async () => {
        if (!selectedProvider) {
            setError('Please select a provider');
            return;
        }

        if (selectedImageFile && imageUploadData && !isRunningBGJob) {
            try {
                setError(undefined);
                setIsRunningBGJob(true);

                await fetch(imageUploadData.signedUrl, {
                    method: 'PUT',
                    body: selectedImageFile,
                    headers: {
                        'x-goog-content-length-range': `${MIN_UPLOAD_LIMIT_BYTES},${MAX_UPLOAD_LIMIT_BYTES}`,
                        'x-goog-meta-provider': selectedProvider,
                    },
                })
                    .then((response) => {
                        if (response.ok) {
                            console.log('Image uploaded successfully');
                            setIsRunningBGJob(false);
                            setModalOpen(true);
                        } else {
                            console.error('Upload failed');
                            throw Error('Upload failed');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        throw error;
                    });
            } catch (error) {
                setIsRunningBGJob(false);
                if (error instanceof Error) {
                    setError(
                        `${error.name}: There has been an error uploading the QR ${
                            error.message
                        }\n${JSON.stringify(error)}`
                    );
                } else {
                    setError(JSON.stringify(error));
                }
            }
        }
    };

    const handleProviderClick = (provider) => {
        setSelectedProvider(provider);
        if (provider) {
            setError(undefined);
        }
    };

    return (
        <div>
            {error ? <p className={styles['error-text']}>{error}</p> : null}
            <div className={styles['filepicker']}>
                <canvas ref={hiddenQRCanvasRef} style={{ display: 'none' }}></canvas>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                    className={styles['input-file']}
                />
                {selectedImageFile ? (
                    <>
                        <span>QR PREVIEW</span>
                        <img
                            src={URL.createObjectURL(selectedImageFile)}
                            alt="Selected"
                            className={styles['preview-image']}
                        />
                    </>
                ) : null}
                <Dropdown
                    defaultSelectedProvider={selectedProvider}
                    onSelect={handleProviderClick}
                    // Should match list of valid providers in functions/src/index.ts
                    options={providersList}
                />

                <ComfyButton
                    type={selectedImageFile ? COMFY_BUTTON_TYPE.INFO : COMFY_BUTTON_TYPE.ACTION}
                    onClick={handleInputFileClick}
                >
                    {`Choose ${selectedImageFile ? 'another ' : 'a '}file`}
                </ComfyButton>
                {selectedImageFile && selectedProvider ? (
                    <ReCAPTCHAWrapper
                        ref={recaptchaRef}
                        onCaptchaActivity={async (value) => {
                            if (!value) {
                                setIsRunningBGJob(false);
                                setImageUploadData(null);
                                return;
                            }
                            setIsRunningBGJob(true);
                            const verifyRecaptchaAndGenerateImageUrl = httpsCallable(
                                functions,
                                'uploadLocation'
                            );
                            verifyRecaptchaAndGenerateImageUrl({
                                recaptchaToken: value,
                                contentType: selectedImageFile.type,
                                selectedProvider,
                            })
                                .then((result) => {
                                    // Read result of the Cloud Function.
                                    const { signedUrl, fileName } = result.data;
                                    setImageUploadData({ signedUrl, fileName });
                                    setIsRunningBGJob(false);
                                })
                                .catch((error) => {
                                    // Getting the Error details.
                                    // const code = error.code;
                                    const message = error.message;
                                    // const details = error.details;
                                    // TODO: Handle
                                    // Handle the error here.
                                    console.error('Error:', message);
                                    setError(message);
                                    setIsRunningBGJob(false);
                                });
                        }}
                    />
                ) : null}
                {isRunningBGJob ? (
                    <LoadingSpinner />
                ) : (
                    <ComfyButton
                        onClick={handleUploadClick}
                        disabled={!imageUploadData || isRunningBGJob}
                    >
                        Submit
                    </ComfyButton>
                )}
            </div>
            <PopupModal
                open={isModalOpen}
                setOpen={setModalOpen}
                onClose={() => [
                    setTimeout(() => {
                        window.location.reload();
                    }, 500),
                ]}
            >
                <PopupModal.Content className="p-6 sm:max-w-sm">
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <PopupModal.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                        >
                            Thanks for submitting!
                        </PopupModal.Title>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                We will review your submission and distribute the SIM card to
                                someone in need through our partner organizations.
                            </p>
                        </div>
                    </div>
                </PopupModal.Content>
            </PopupModal>
        </div>
    );
};

export default FilePicker;
